import {
  confirmed,
  email,
  max,
  numeric,
  required
} from 'vee-validate/dist/rules'

import { Dict } from '@/utils/interfaces'
import { extend } from 'vee-validate'
import { i18n } from '@/locales/i18n-setup'

extend('email', {
  ...email,
  message: _ => i18n.t('errors.invalidEmail') as string
})

extend('required', {
  ...required,
  message: _ => i18n.t('errors.required') as string
})

extend('exactTotal', {
  validate(value: string, args: Dict) {
    const total = parseFloat(args.total)
    const amount = parseFloat(value)

    return total === amount
  },
  params: ['total'],
  message: (_, values) =>
    i18n.t('errors.exactTotal', {
      total: i18n.n(values.total, 'currency', 'en-es')
    }) as string
})

extend('monthYearDate', {
  validate(value: string) {
    const pattern = new RegExp(/(0[1-9]|1[0-2])\/(\d{2})/)
    return pattern.test(value)
  },
  message: _ => i18n.t('errors.invalidYearDate') as string
})

extend('maxLength', {
  ...max,
  message: (_, values) => i18n.t('errors.maxLength', values) as string
})

extend('CVV2', {
  validate(value: string) {
    const pattern = new RegExp(/(\d{3,4})/)
    return pattern.test(value)
  },
  message: _ => i18n.t('errors.invalidCVV2') as string
})

extend('passwordConfirmed', {
  ...confirmed,
  message: 'Passwords do not match'
})

extend('passwordRequirements', {
  validate(value: string) {
    const passwordLength = 8 <= value.length
    const hasUpperCase = /[A-Z]/.test(value)
    const hasLowerCase = /[a-z]/.test(value)
    const hasNumbers = /\d/.test(value)
    const hasSpecialCharacters = /\W/.test(value)
    return (
      passwordLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialCharacters
    )
  },
  message: _ => i18n.t('errors.invalidPassword') as string
})

extend('amountBetween', {
  validate(value: string, args: Dict) {
    const minAmount = parseFloat(args.min)
    const maxAmount = parseFloat(args.max)
    const amount = parseFloat(value)

    return minAmount <= amount && amount <= maxAmount
  },
  params: ['min', 'max'],
  message: (_, values) => i18n.t('errors.amountBetween', values) as string
})

extend('minAmount', {
  validate(value: string, args: Dict) {
    const minAmount = parseFloat(args.min)
    const amount = parseFloat(value)
    return minAmount <= amount
  },
  params: ['min'],
  message: (_, values) => i18n.t('errors.minAmount', values) as string
})

extend('maxAmount', {
  validate(value: string, args: Dict) {
    const maxAmount = parseFloat(args.max)
    const amount = parseFloat(value)
    return maxAmount >= amount
  },
  params: ['max'],
  message: (_, values) => i18n.t('errors.maxAmount', values) as string
})

extend('numeric', numeric)

extend('full_name', {
  ...required,
  validate: value => {
    const pattern = /\b\w+\b(?:\s+\b\w+\b)+/
    return pattern.test(value)
  },
  message: 'This field must be a full name'
})
