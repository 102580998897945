export default {
  multipay: (id: string) => `/api/accounts/${id}/pay_multi/`,
  resetAuthRequest: () => `/api/password-reset/`,
  getOptins: () => `/api/optin/`,
  optinDetail: (optinID: string) => `/api/optin/${optinID}/`,
  getAccountTransactions: (accountID: string) =>
    `/api/accounts/${accountID}/transaction/`,
  getAccountTransactionDetail: (accountID: string, transactionID: string) =>
    `/api/accounts/${accountID}/transaction/?transaction_id=${transactionID}`,
  paymentLink: () => `/api/payment-link/`,
  listPaymentMethods: () => `/api/payment_methods/`,
  getPaymentMethodDetail: (id: string) => `/api/payment_methods/${id}/`,
  createPaymentMethod: () => `/api/payment_methods/`,
  updatePaymentMethod: (id: string) => `/api/payment_methods/${id}/`,
  partialUpdatePaymentMethod: (id: string) => `/api/payment_methods/${id}/`,
  retrievePaymentMethodPromises: (id: string) =>
    `/api/payment_methods/${id}/payment_method_accounts/`,
  retrieveScheduledPayments: (id: string) =>
    `/api/payment_methods/${id}/scheduled_payments/`,
  deletePaymentMethod: (id: string) =>
    `/api/payment_methods/${id}/delete_payment_method/`,
  listAccountRoots: () => `/api/accounts/`,
  retrieveAccountRoot: (id: string) => `/api/accounts/${id}/`,
  listCustomerRoots: () => `/api/customers/`,
  updateLanguage: () => `/api/customers/set_language/`,
  listCustomerInvites: () => `/api/customer_invites/`,
  createBlytzTokenObtainPair: () => `/api/token/`,
  createTokenRefresh: () => `/api/token/refresh/`,
  invoices: () => '/api/invoices/',
  receiptInvoices: () => '/api/receipt-invoices/',
  invoiceDetail: (id: string) => `/api/invoices/${id}/`,
  createTwilioCall: () => `/api/twilio/calls/`,
  calculateFee: (id: string) => `/api/accounts/${id}/calculate_fee/`,
  cancelScheduled: (id: string) =>
    `/api/accounts/${id}/cancel_scheduled_payments/`,
  requestPaymentLinks: () => `/api/request-payment-links/`,
  listPromises: (id: string) => `/api/accounts/${id}/promises/`,
  arrangePromises: () => `/api/promises/arrange_payments/`,
  sendPaymentLinks: () => `/api/send-payment-link/`,
  merchantInfo: (id: string) => `/api/merchant-info/${id}/`,
  registerCustomer: () => `/api/register-customer/`,
  getPayments: () => `/api/payments/`,
  getPaymentDetail: (id: string) => `/api/payments/${id}/`,
  createPayment: () => `/api/payments/`,
  posPayment: (merchant_org_id: any) =>
    `/api/merchants/${merchant_org_id}/pos-payment/`,
  publicPosPayment: (merchant_org_id: any) =>
    `/api/merchants/${merchant_org_id}/public-pos-payment/`,
  updatePaymentRequest: (merchant_org_id: any, payment_request_id: any) =>
    `/api/merchants/${merchant_org_id}/requested_payment/${payment_request_id}/`,
  sendPaymentReceipt: (merchant_org_id: any, id: string) =>
    `/api/merchants/${merchant_org_id}/payments/${id}/send_receipt/`,
  retrieveBinInfo: (bin: string) => `/api/bins/${bin}/`,
  changeAutopay: (id: string) => `/api/accounts/${id}/change_autopay/`,
  incommGenerateBarcode: ()=> `/api/barcode/`,
  // incommGetBarcodeByInvoice: (id: string) => `/api/barcode/?invoice_id=${id}`,
  incommGetUpcRates: (id: string) => `/api/incomm-invoice/${id}`,
  incommGetInvoiceStatus: (id: string) => `/api/incomm-invoice/${id}?check_status=true`,
  verifyPaymentMethod:`/api/payment_methods/verify/`,
}
